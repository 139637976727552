(function ($) {
  "use strict";
  /*---- Variables ------------------------------*/
  var $window = $(window);
  var $header = $('#js-header');
  var $navburger = $('#js-navburger');
  var $menu = $('#js-menu');
  var $submenuItem = $menu.find('.has-submenu');
  $header.addClass('is-small');
  var scroll = $(this).scrollTop();

  /*---- Events ------------------------------*/
  $window.bind('scroll', function() {
    scroll = $(this).scrollTop();
    if (scroll > 0) {
      $header.removeClass('is-small');
    } else {
      $header.addClass('is-small');
    }
  });

  $header.bind('mouseenter', function () {
    if ($header.hasClass('is-small')) {
      $header.removeClass('is-small');
    }
  });

  $header.bind('mouseleave', function () {
    if (scroll === 0) {
      $header.addClass('is-small');
    }
  });


})(jQuery);